import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cai-dat-tai-khoan',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FormCapNhatThongTin} from "@features/auth/implements/account-first-login/require-update-info.component";
import { InputOtpComponent } from "./components/input-otp/input-otp.component";
import { ErrorViewComponent } from "./components/error-view/error-view.component";
import { VerifyDeviceComponent } from "./components/verify-device/verify-device.component";

@NgModule({
  declarations: [],
  imports: [
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: FormCapNhatThongTin,
      },
      {
        path: 'v', // v= verify
        component: InputOtpComponent
      },
      {
        path: 'er', // Error when get fisrt info
        component: ErrorViewComponent
      },
      {
        path: 'device',
        component: VerifyDeviceComponent,
      }
    ])
  ],
})
export class VerifyAccountFirstLoginModule {
}

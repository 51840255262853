<!-- STATE 1 -->
<h1 class="fw-500 fs-xxl mb-5 mt-5 text-center" *transloco="let translated">
  {{ translated("auth.component.accountfirstLogin.verifyEmail.title") }}
  <!-- Xác minh địa chỉ email -->
  <br />
  <small>
    {{
      translated(
        "auth.component.accountfirstLogin.verifyEmail.titleDescription"
      )
    }}
    <!-- Hệ thống cần xác minh địa chỉ email, vui lòng cung cấp email và nhấn tiếp -->
    <!-- tục, chúng tôi sẽ gửi đến bạn mã OTP gồm 6 chữ số vào địa chỉ email này. -->
  </small>
</h1>

<div
  class="text-danger font-italic pt-4"
  style="clear: both; width: 100%"
  *ngIf="displayErrorMessage"
>
  {{ displayErrorMessage }}
</div>
<div class="col-xl-12" *transloco="let translated">
  <form>
    <div class="form-group">
      <input
        class="form-control form-control-lg"
        placeholder="Nhập email của bạn để xác thực..."
        [formControl]="emailForm"
        type="text"
        (keydown.enter)="onVerifyEmail($event)"
        [class.is-invalid]="
          emailForm.invalid && (emailForm.dirty || emailForm.touched)
        "
      />

      <div
        class="invalid-feedback"
        *ngIf="emailForm.invalid && (emailForm.dirty || emailForm.touched)"
      >
        {{
          translated(
            "auth.component.accountfirstLogin.verifyEmail.wrongEmailFormatErrorMessage"
          )
        }}
        {{ "abc@gmail.com" }}
      </div>

      <div class="input-group-btn mt-3">
        <div class="row d-flex justify-content-center mt-4 p-1">
          <button
            class="btn btn-default btn-lg mr-2"
            type="button"
            (click)="this.navigateToLogin()"
          >
            {{
              translated("auth.component.accountfirstLogin.verifyEmail.goBack")
            }}
          </button>
          <button
            class="btn btn-primary btn-lg px-5"
            type="button"
            [disabled]="this.emailForm.invalid || this.showLoading"
            (click)="onVerifyEmail($event)"
            *ngIf="!haveInternalServerError"
          >
            <span
              *ngIf="this.showLoading"
              class="spinner-border spinner-border-sm mr-1 mt-1"
              role="status"
              aria-hidden="true"
            ></span>
            {{
              translated(
                "auth.component.accountfirstLogin.verifyEmail.continue"
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { InputUserNameHelper } from '@features/auth/shared/helpers/display-email-error.helper';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  selector: 'app-input-username',
  templateUrl: './input-username.component.html',
})
export class InputUsernameComponent implements OnInit {
  @Output() hintEmail = new EventEmitter<string>();
  @Output() username = new EventEmitter<string>();
  @Output() goBack = new EventEmitter<void>();

  public service = inject(ForgotPasswordService);
  public formBuilder = inject(FormBuilder);
  public router = inject(Router);

  public displayErrorMessage = '';
  public usernameForm!: FormGroup;
  public showSpinner = false;

  ngOnInit() {
    this.usernameForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  getHintEmail() {
    this.displayErrorMessage = '';

    if (this.usernameForm.get('username')?.hasError('pattern')) {
      this.displayErrorMessage =
        InputUserNameHelper.displayError('wrongFormat');
      return;
    }

    if (this.usernameForm.get('username')?.hasError('required')) {
      this.displayErrorMessage = InputUserNameHelper.displayError('empty');
      return;
    }

    if (this.usernameForm.invalid) {
      return;
    }

    this.showSpinner = true;
    this.usernameForm.disable();
    const { username } = this.usernameForm.getRawValue();

    this.service
      .getHintEmail(username)
      .pipe(
        finalize(() => {
          this.usernameForm.enable();
          this.showSpinner = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.hintEmail.emit(value);
          this.username.emit(username);
        },
        error: (err) => {
          console.error(err);
          this.displayErrorMessage =
            InputUserNameHelper.displayError('usernameNotFound');
        },
      });
  }
}

<form
  [formGroup]="inputEmailForm"
  (keydown.enter)="onVerifyEmail()"
  *transloco="let translated"
>
  <div class="modal-body">
    <div class="form-group">
      <label class="form-label" for="email"
        >{{
          translated("auth.component.forgotPassword.inputEmail.enterEmailLabel")
        }}
        - <strong>{{ this.hintEmail }}</strong>
        <span class="text-danger"> (*)</span></label
      >

      <input
        formControlName="email"
        type="text"
        id="email"
        class="form-control form-control-lg"
        placeholder="Ví dụ: abc@gmail.com"
      />
      <!-- TOTO: HANDLE ERROR CODE -->
      <div class="invalid-feedback d-block" *ngIf="this.displayErrorMessage">
        <!-- {{
          translated(
            "auth.component.forgotPassword.inputEmail.emailWrongFormatError"
          )
        }}
        {{
          translated(
            "auth.component.forgotPassword.inputEmail.emailEmptyError"
          )
        }}
        {{
          translated(
            "auth.component.forgotPassword.inputEmail.emailNotfoundError"
          )
        }} -->
        {{ this.displayErrorMessage }}
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-center row pb-0">
    <div class="col-auto m-0">
      <button
        type="button"
        (click)="this.goBack.emit()"
        class="btn-block btn btn-lg btn-outline-default waves-effect waves-themed"
      >
        <span>
          {{ translated("auth.component.forgotPassword.inputEmail.goBack") }}
        </span>
      </button>
    </div>
    <div class="col col-sm-5 col-md-4 col-lg-3 col-xl-6 m-0">
      <button
        type="button"
        (click)="this.onVerifyEmail()"
        class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed"
      >
        @if (this.showSpinner) {
        <span
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        }
        <span>
          {{ translated("auth.component.forgotPassword.inputEmail.continue") }}
        </span>
      </button>
    </div>
  </div>
</form>

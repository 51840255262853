import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPageComponent } from './account.component';
import { PageAccountSettingRoutingModule } from './account.routing.module';

@NgModule({
  imports: [CommonModule, PageAccountSettingRoutingModule],
  providers: [],
  declarations: [AccountPageComponent],
})
export class AccountSettingModule {}
  
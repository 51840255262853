import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputUsernameComponent } from '@features/auth/implements/forgot-password/components-steps/input-username/input-username.component';
import { InputEmailComponent } from '@features/auth/implements/forgot-password/components-steps/input-email/input-email.component';
import { NotifyAfterSendEmailComponent } from '@features/auth/implements/forgot-password/components-steps/notify/notify.component';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    InputUsernameComponent,
    InputEmailComponent,
    NotifyAfterSendEmailComponent,
    AuthLayoutComponent,
    TranslocoModule,
  ],
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  public router = inject(Router);
  private readonly service = inject(ForgotPasswordService);

  constructor() {}

  CONTROLLER_STEP = {
    INPUT_USER_NAME: 0,
    INPUT_EMAIL_ADDRESS: 1,
    NOTIFY: 2,
  };
  CURRENT_STEP = 0;

  formValues = {
    hintEmail: '',
    username: '',
    email: '',
  };

  displayLogicError: string = '';

  ngOnInit() {}

  hintEmailEmit($event: string) {
    this.formValues.hintEmail = $event;
    this.CURRENT_STEP = 1;
  }

  // Navigate:
  navigateToLogin = () => {
    this.router.navigate(['login']);
  };

  resendEmail = () => {
    this.service
      .forgotPassword(this.formValues.username, this.formValues.email)
      .subscribe({
        next: (value) => {},
        error: (err) => {
          this.displayLogicError = err.message;
          console.log(err);
        },
      });
  };
}

<div class="card h-100 p-4 rounded overflow-hidden position-relative m-10">
  <ng-content></ng-content>
  <footer class="page-footer mt-4 border-top-1" role="contentinfo">
    <div class="d-flex align-items-center flex-1 text-muted">
      <span class="hidden-md-down fw-700"
        >2020 © SmartAdmin by&nbsp;
        <a
          href="https://www.gotbootstrap.com"
          class="text-primary fw-500"
          title="gotbootstrap.com"
          target="_blank"
          >gotbootstrap.com</a
        ></span
      >
    </div>
    <div>
      <ul class="list-table m-0">
        <li>
          <a href="intel_introduction.html" class="text-secondary fw-700"
            >Trợ giúp</a
          >
        </li>

        <li class="pl-3">
          <a href="info_app_licensing.html" class="text-secondary fw-700"
            >Điều khoản</a
          >
        </li>
        <li class="pl-3">
          <a href="info_app_docs.html" class="text-secondary fw-700"
            >Quyền riêng tư</a
          >
        </li>
        <li class="pl-3 fs-xl">
          <a
            href="https://wrapbootstrap.com/user/MyOrange"
            class="text-secondary"
            target="_blank"
            ><i class="fal fa-question-circle" aria-hidden="true"></i
          ></a>
        </li>
      </ul>
    </div>
  </footer>
</div>

import {
  Component,
  OnInit,
  inject,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { take, finalize } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EMAIL_PATTERN } from '@features/auth/shared/helpers/email-pattern';
import { InputUserNameHelper } from '@features/auth/shared/helpers/display-email-error.helper';
import { ForgotPasswordService } from '@features/auth/core/domain/forgot-password/forgot-password.service';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.css'],
})
export class InputEmailComponent implements OnInit {
  @Input() hintEmail: string = '';
  @Input() username: string = '';
  @Output() emitSuccess = new EventEmitter<string>();
  @Output() goBack = new EventEmitter<void>();

  private readonly service = inject(ForgotPasswordService);
  public formBuilder = inject(FormBuilder);

  displayErrorMessage = '';

  public inputEmailForm!: FormGroup;
  public showSpinner: boolean = false;

  ngOnInit() {
    // THIS API MUST BE CALL;
    this.inputEmailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      username: [this.username, [Validators.required]],
    });
  }

  onVerifyEmail() {
    this.displayErrorMessage = '';

    if (this.inputEmailForm.get('email')?.hasError('pattern')) {
      this.displayErrorMessage =
        InputUserNameHelper.displayError('wrongFormat');
      return;
    }

    if (this.inputEmailForm.get('email')?.hasError('required')) {
      this.displayErrorMessage = InputUserNameHelper.displayError('empty');
      return;
    }

    this.showSpinner = true;
    const { email, username } = this.inputEmailForm.getRawValue();

    this.service
      .forgotPassword(username, email)
      .pipe(
        take(1),
        finalize(() => {
          this.showSpinner = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.inputEmailForm.markAsPristine();
          this.emitSuccess.emit(email);
        },
        error: (err) => {
          if (err.code == 404 || err.code == -1) {
            this.displayErrorMessage =
              InputUserNameHelper.displayError('emailNotFound');
            return;
          }

          this.displayErrorMessage = InputUserNameHelper.displayError(
            'internalServerError'
          );
        },
      });
  }
}

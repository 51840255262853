@if (this.currentStep !== -1) {
<app-auth-layout>
  <ng-container [ngSwitch]="this.currentStep">
    <!-- đổi pass -->
    <form-change-password
      *ngSwitchCase="this.CONTROLER_STEP.CHANGE_PASSWORD"
      (success)="activeFormByKey()"
    >
    </form-change-password>

    <!-- đổi số điện thoại -->

    <form-email *ngSwitchCase="this.CONTROLER_STEP.VERIFY_EMAIL"> </form-email>

    <app-finished-form *ngSwitchCase="this.CONTROLER_STEP.FINISH" />

    <!-- default case if none of the above matches -->
    <div *ngSwitchDefault>
      <!-- Handle default case if needed -->
    </div>
  </ng-container>
</app-auth-layout>
} @else {
<app-auth-layout *transloco="let translated">
  <div class="p-3">
    <div class="d-flex align-items-center">
      <strong
        >{{
          translated("auth.component.accountfirstLogin.loadingMessage")
        }}...</strong
      >
      <div
        class="spinner-border ml-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>
</app-auth-layout>
}

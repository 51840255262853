import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountPageComponent } from './account.component';

const pageBienTapChuyenMonRoutes: Routes = [
  {
    path: '',
    component: AccountPageComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./account-info/account-info.component').then(
            (component) => component.AccountInfoComponent,
          ),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(pageBienTapChuyenMonRoutes)],
  exports: [RouterModule],
})
export class PageAccountSettingRoutingModule {}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { CommonModule } from '@angular/common';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { Subject } from 'rxjs';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CountdownButtonDirective,
    TranslocoModule,
  ],
  selector: 'form-email',
  templateUrl: './form-email.component.html',
  styleUrls: ['./form-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  private readonly appRouting = inject(AppRoutingService);
  private readonly routing = inject(Router);
  private readonly service = inject(VerifyAccountFirstLoginService);

  public emailForm!: FormControl;

  public showLoading: boolean = false;
  public displayErrorMessage!: string;
  public haveInternalServerError: boolean = false;

  destroy$$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
  ngOnInit() {
    this.emailForm = new FormControl('', [
      Validators.email,
      Validators.required,
    ]);
  }

  // TODO: Call difference api to verify email
  onVerifyEmail = (event: any) => {
    // The preventDefault() method cancels the event if it is cancelable, meaning that the default action that belongs to the event will not occur.
    event.preventDefault();
    this.showLoading = true;
    this.emailForm.disable();

    // TODOL: Gọi api server to verify email address then push message if success;

    this.showLoading = false;
    this.emailForm.enable();
    if (this.emailForm.invalid) {
      return;
    }

    this.service
      .verifyEmailAddress(this.emailForm.getRawValue(), {})
      .subscribe({
        next: (value) => {
          if (value) {
            this.routing.navigate(['cap-nhat-thong-tin', 'v'], {
              queryParams: {
                t: 'email',
                e: this.emailForm.value,
              },
            });
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  };

  navigateToLogin() {
    this.appRouting.navigateToLoginPage();
  }
}

import {IForgotPassword} from "@features/auth/core/domain/forgot-password/forgot-password.interface";
import {inject, Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CoreAuthConfigService} from "@features/auth/core-auth-config.service";
import {URI_APIs} from "@features/auth/const";

@Injectable()
export class ForgotPasswordService implements IForgotPassword {
  private readonly httpClient = inject(HttpClient);
  private readonly conf = inject(CoreAuthConfigService);

  forgotPassword(username: string, email: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.FORGOT_PASSWORD);
    const requestBody = {
      username: username,
      email: email,
    };
    return this.httpClient.post(url, requestBody);
  }

  getHintEmail(username: string): Observable<any> {
    const url = this.conf.buildURL(URI_APIs.FORGOT_PASSWORD_HINT_EMAIL);
    const requestBody = {
      username: username,
    };
    return this.httpClient
      .post(url, requestBody)
      .pipe(map((e: any) => e?.result ?? ''));
  }
}

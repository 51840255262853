<form
  [formGroup]="usernameForm"
  (keydown.enter)="getHintEmail()"
  *transloco="let translated"
>
  <div class="modal-body">
    <div class="form-group">
      <label class="form-label" for="username">
        {{
          translated(
            "auth.component.forgotPassword.inputUsername.inputUsernameLabel"
          )
        }}
        <!-- Tên đăng nhập của bạn -->
        <span class="text-danger">(*)</span>
      </label>
      <input
        formControlName="username"
        type="text"
        id="username"
        class="form-control form-control-lg"
        placeholder="Vui lòng nhập tên đăng nhập"
      />

      <div class="invalid-feedback d-block" *ngIf="this.displayErrorMessage">
        <!-- {{
          translated("auth.component.forgotPassword.inputUsername.usernameNotFoundError")
        }} -->
        {{ this.displayErrorMessage }}
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center row pb-0">
    <div class="col-auto m-0">
      <button
        type="button"
        (click)="this.goBack.emit()"
        class="btn-block btn btn-lg btn-outline-default waves-effect waves-themed"
      >
        <span>
          {{ translated("auth.component.forgotPassword.inputUsername.goBack") }}
        </span>
      </button>
    </div>
    <div class="col col-sm-5 col-md-4 col-lg-3 col-xl-6 m-0">
      <button
        (click)="this.getHintEmail()"
        type="button"
        class="btn-block btn btn-lg btn-primary px-5 waves-effect waves-themed"
      >
        @if (this.showSpinner) {
        <span
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        }
        <span>
          {{
            translated(
              "auth.component.forgotPassword.inputUsername.sendMessage"
            )
          }}
        </span>
      </button>
    </div>
  </div>
</form>

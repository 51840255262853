<div class="col-xl-12 text-center" *transloco="let translated">
  <h2 class="fs-xxl fw-500 mt-4 text-center">
    <small class="h5 fw-300 mt-3 mb-5 opacity-70">
      {{ translated("auth.component.forgotPassword.notifi.title1") }}
      <!-- Chúng tôi đã gửi tin nhắn đến -->
      <strong>{{ this.email }}</strong>
      {{ translated("auth.component.forgotPassword.notifi.title2") }}
      <!-- Kèm theo mật khẩu mạc đinh, vui lòng kiểm tra email và đăng nhập lại -->
    </small>
  </h2>

  <div class="justify-content-center text-center">
    <button
      class="btn btn-default btn-lg mr-2 text-center"
      type="button"
      (click)="this.resendEmail.emit()"
      [countdownButton]="5"
      countdownMessage="Tôi chưa nhận được email. Gửi lại"
    >
      {{ translated("auth.component.forgotPassword.notifi.resend") }}
      <!-- Tôi chưa nhận được email. Gửi lại -->
    </button>

    <button
      class="btn btn-primary btn-lg mr-2"
      type="button"
      (click)="this.goToLogin.emit()"
    >
      <!-- Quay về trang đăng nhập -->
      {{ translated("auth.component.forgotPassword.notifi.goBack") }}
    </button>
  </div>
</div>

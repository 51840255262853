import { Injectable, inject, isDevMode } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, TimeoutError, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { ErrorBase } from '@core/models/error.model';
import { Router } from '@angular/router';
import { CoreAuthConfigService } from '@features/auth/core-auth-config.service';
import { IResponseErrorHandler } from './response-error-handler';
import { AccessTokenExpiredError } from '../domain/login/models/version-2/auth-error-version2.model';

//DOC: https://angular.io/guide/http-intercept-requests-and-responses
@Injectable()
export class ApiVersion2ResponseInterceptor implements HttpInterceptor, IResponseErrorHandler {
  router = inject(Router);
  conf = inject(CoreAuthConfigService);

  constructor(private readonly modalService: NgbModal) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * PIPE: [---> handleGlobalError ---> mappingHttpErrorResponseToErrorBase ----> ]
     */
    const API_VERSION_HEADER_KEY = 'X-Api-Version';
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleHttpError(error);
        const apiVersion = error.headers.get(API_VERSION_HEADER_KEY);
        return throwError(() => new ErrorBase(error, apiVersion));
      })
    );
  }

  handleHttpError = (error: HttpErrorResponse) => {
    const errorCode = error.error?.reasons?.options?.code;
    switch (error.status) {
      case HttpStatusCode.Unauthorized:

        const NOT_FOUND_TOKEN_CODE = 0;
        const NOUT_FOUND_API_CODE = -1;

        switch (errorCode) {
          case NOT_FOUND_TOKEN_CODE:
            this.modalService.dismissAll();
            this.router.navigate(['login'], {
              queryParams: { er: AccessTokenExpiredError._code },
            });
            break;

          default: 
            const mgs = [
              {
                API: error.url,
                Message: 'API Not declared - please declare them (ADMIN)',
              },
            ];
            isDevMode() && console.table(mgs);
            break;
        }
        return EMPTY;

      case HttpStatusCode.Forbidden:

        const NEED_TO_VERIFY_AUTH_INFO_CODE = -3;
        const NOT_ALLOW_TO_USE_API = -4;
        const NEED_TO_VERIFY_DEVICE = -5;

        switch (errorCode) {
          case NEED_TO_VERIFY_AUTH_INFO_CODE:
            this.router.navigate(['cap-nhat-thong-tin']);
            break;
          case NEED_TO_VERIFY_DEVICE:
            this.router.navigate(['cap-nhat-thong-tin/device']);
            break;

          case NOT_ALLOW_TO_USE_API:
            const mgs = [
              {
                API: error.url,
                Message: 'API Not declared - please declare them (ADMIN)',
              },
            ];
            isDevMode() && console.table(mgs);
            break;
          default:
            break;
        }
        return EMPTY;
      default:
        break;
    }

    return of(error);
  };
}

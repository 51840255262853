import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, CountdownButtonDirective, TranslocoModule],
  selector: 'app-notify-after-send-email',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css'],
})
export class NotifyAfterSendEmailComponent implements OnInit {
  @Input() email: string = '';
  @Input() username: string = '';

  @Output() resendEmail = new EventEmitter<void>();
  @Output() goToLogin = new EventEmitter<void>();

  ngOnInit(): void {}
}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOtpInputComponent } from '@features/auth/shared/components/ng-otp-input/ng-otp-input.component';
import { Config } from '@features/auth/shared/components/ng-otp-input/config';
import { CountdownButtonDirective } from '@features/auth/shared/directives/coutdown-and-disable.directive';
import { finalize, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {
  SpamResendCodeError,
  VerifyOtpCodeExpiredError,
  VerifyOtpCodeNotMatchError,
} from '@features/auth/core/domain/account-first-login/models/verify-otp-code.error';
import { AuthInternalServerError } from '@features/auth/core/domain/login/models/version-2/auth-error-version2.model';
import { VERIFY_OTP_ERROR_CODE } from '@features/auth/implements/account-first-login/components/input-otp/config';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { VerifyOTPCodeService } from '@features/auth/core/domain/account-first-login/verify-code-otp.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { PUBLIC_ASSET_PATH } from '@features/auth/const';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgOtpInputComponent,
    CountdownButtonDirective,
    AuthLayoutComponent,
    TranslocoModule,
  ],
  selector: 'app-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.css'],
})
export class InputOtpComponent implements OnInit, OnDestroy {
  constructor() {}

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly routing = inject(Router);
  public readonly service = inject(VerifyAccountFirstLoginService);
  public readonly verifyOTPService = inject(VerifyOTPCodeService);

  destroy$$ = new Subject<void>();

  imagePath = PUBLIC_ASSET_PATH + '/img/email-send.png';

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  typeVerify = {
    EMAIL: 'email',
    PHONE: 'sms', // sms
    DEVICE: 'device',
  };

  switchViewVerifyCode: string = '';
  emailAddress = '';

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$$))
      .subscribe((params) => {
        this.switchViewVerifyCode = params['t'] ?? '';
        this.emailAddress = params['e'] ?? '';
      });
  }

  public INPUT_VERIFY_CODE_CONFIG: Config = {
    length: 6,
    allowNumbersOnly: true,
    placeholder: '_',
    containerClass: 'form-group',
  };

  public OTP: string = '';
  // UI
  public isInProcess: boolean = false;

  public displayErrorByCode: number = -1;
  public ERROR_CODE = VERIFY_OTP_ERROR_CODE;

  inputOTPChange(event: any) {
    this.OTP = event;
  }

  // TODO: resend OTP.
  onSend() {
    this.displayErrorByCode = -1;
    this.isInProcess = true;

    this.verifyOTPService
      .confirmOTPCode(this.OTP)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isInProcess = false;
          }, 700);
        })
      )
      .subscribe({
        next: (response) => {
          this.goBack();
        },
        error: (err) => {
          setTimeout(() => {
            if (err instanceof VerifyOtpCodeNotMatchError) {
              this.displayErrorByCode = VerifyOtpCodeNotMatchError._code;
            }
            if (err instanceof VerifyOtpCodeExpiredError) {
              this.displayErrorByCode = VerifyOtpCodeNotMatchError._code;
            }
            if (err instanceof SpamResendCodeError) {
              this.displayErrorByCode = SpamResendCodeError._code;
            }
            if (err instanceof AuthInternalServerError) {
              this.displayErrorByCode = AuthInternalServerError._code;
            }
          }, 700);
        },
      });
  }

  onResend() {
    this.displayErrorByCode = -1;
    switch (this.switchViewVerifyCode) {
      case this.typeVerify.EMAIL:
        this.service.verifyEmailAddress(this.emailAddress, {}).subscribe({});
        break;
      case this.typeVerify.DEVICE:
        this.service.sendVerifyDeviceCode().subscribe({});
        break;
      default:
        break;
    }
  }

  goBack() {
    this.routing.navigate(['cap-nhat-thong-tin'], {
      queryParams: { fr: 'otp' },
    });
  }
}

<div class="col-xl-12" *transloco="let translated">
  <h2 class="fs-xxl fw-500 mt-4 text-center">
    <strong>

    {{ translated('auth.component.accountfirstLogin.finishedForm.title')}}
    </strong>
    <small class="h5 fw-300 mt-3 mb-5  opacity-70 ">
     {{ translated('auth.component.accountfirstLogin.finishedForm.title1')}}
    </small>
  </h2>
</div>
<div *transloco="let translated" class="modal-footer justify-content-center border-bottom">
  <button type="button" (click)="this.navigateToLogin()"
          class="mr-3 btn btn-outline-default btn-lg  waves-effect waves-themed">
    <span>
     {{ translated('auth.component.accountfirstLogin.finishedForm.goBack')}}

    </span>
  </button>

  <button type="submit" (click)="this.onNavigateToHome()" class="btn btn-primary btn-lg px-5 waves-effect waves-themed">
    <span>
     {{ translated('auth.component.accountfirstLogin.finishedForm.continueUseApp')}}
    </span>
  </button>
</div>

<app-auth-layout *transloco="let translated">
  <!-- #Body -->
  @switch (this.switchViewVerifyCode) { @case (this.typeVerify.EMAIL) {
  <h1 class="fw-500 mb-2 mt-5 text-center">
    {{ translated("auth.component.accountfirstLogin.inputOtp.typeEmailTile") }}
  </h1>
  } @case (this.typeVerify.PHONE) {
  <h1 class="fw-500 mb-2 mt-5 text-center">
    {{ translated("auth.component.accountfirstLogin.inputOtp.typePhoneTile") }}
  </h1>
  } @case (this.typeVerify.DEVICE) {
  <h1 class="fw-500 fs-xxl mb-2 mt-5 text-center">
    <!-- Hệ thống đã gửi mã xác minh đến địa chỉ email đã đăng ký -->
    {{ translated("auth.component.accountfirstLogin.inputOtp.typeDeviceTile") }}
  </h1>
  } }
  <p class="fs-xl text-center">
    {{
      translated("auth.component.accountfirstLogin.inputOtp.titleDescription1")
    }}
    <!-- Hệ thống sẽ gửi bạn email kèm mã xác minh 6 chử số - hãy điền mã này vào ô -->
    <!-- bên dưới để xác minh. -->
    <br />
    {{
      translated("auth.component.accountfirstLogin.inputOtp.titleDescription2")
    }}
    <!-- Nếu chưa nhận được email trong vòng 60 giây bạn có thể nhấn nút gửi lại bên -->
    <!-- dưới để thực hiện lại yêu cầu. -->
    <br />
    <strong>
      {{
        translated(
          "auth.component.accountfirstLogin.inputOtp.titleDescription3"
        )
      }}
      <!-- Lưu ý: Mã này chỉ được dùng một lần và không cung cấp mã này cho bất kỳ ai. -->
    </strong>
  </p>
  <div *transloco="let translated">
    <div class="modal-body">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="d-flex justify-content-center">
          <ng-otp-input
            [config]="this.INPUT_VERIFY_CODE_CONFIG"
            (onInputChange)="inputOTPChange($event)"
          >
          </ng-otp-input>
        </div>

        @switch (this.displayErrorByCode) { @case
        (this.ERROR_CODE.VerifyOtpCodeNotMatchError) {
        <div class="invalid-feedback fs-md d-block mt-1">
          {{
            translated(
              "auth.component.accountfirstLogin.inputOtp.error.VerifyOtpCodeNotMatchError"
            )
          }}
          <!-- Mã xác minh không khớp, vui lòng kiểm tra lại -->
        </div>
        } @case (this.ERROR_CODE.VerifyOtpCodeExpiredError) {
        <div class="invalid-feedback fs-md d-block mt-1">
          {{
            translated(
              "auth.component.accountfirstLogin.inputOtp.error.VerifyOtpCodeExpiredError"
            )
          }}
          <!-- Mã xác minh này đã hết hạn, vui lòng thực hiện thao tác lại -->
        </div>
        } @case (this.ERROR_CODE.SpamResendCodeError) {
        <div class="invalid-feedback fs-md d-block mt-1">
          {{
            translated(
              "auth.component.accountfirstLogin.inputOtp.error.SpamResendCodeError"
            )
          }}
          <!-- Không thể gửi yêu cầu quá tần xuất quy định, vui lòng thử lại sau ít phút -->
        </div>
        } @case (-1) { } @default {
        <div class="invalid-feedback fs-md d-block mt-1">
          {{ translated("auth.shared.errors.TechnicalError") }}
          <!-- Lỗi kỹ thuật: Vui lòng liên hệ với chúng tôi để khắc phục hoặc thử lại sau. -->
        </div>
        } }
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-outline-default mt-5 border-top-0 border-left-0 border-right-0 border-bottom-0"
            style="cursor: pointer"
            [countdownButton]="5"
            [countdownMessage]="
              translated('auth.component.accountfirstLogin.inputOtp.resendOTP')
            "
            (click)="this.onResend()"
          >
            {{
              translated("auth.component.accountfirstLogin.inputOtp.resendOTP")
            }}
          </button>
        </div>
      </div>
    </div>

    <div class="input-group-btn mt-3">
      <div class="row d-flex justify-content-center">
        <button
          class="btn btn-default btn-lg mr-2"
          type="button"
          (click)="this.goBack()"
        >
          {{ translated("auth.component.accountfirstLogin.inputOtp.goBack") }}
          <!-- Quay lại -->
        </button>
        <button
          class="btn btn-primary btn-lg px-5"
          type="button"
          [disabled]="
            this.OTP.length !== INPUT_VERIFY_CODE_CONFIG.length ||
            this.isInProcess
          "
          (click)="this.onSend()"
        >
          @if (this.isInProcess) {
          <span
            class="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          >
          </span>
          }
          <span>
            {{
              translated("auth.component.accountfirstLogin.inputOtp.confirm")
            }}
          </span>
        </button>
      </div>
    </div>
  </div>
</app-auth-layout>

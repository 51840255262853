<app-auth-layout *transloco="let translated">
  <h1 class="fw-500 mb-2 mt-5 text-center">
    Lỗi kỹ thuật
    <br />
    Lỗi xãy ra khi hệ thống xác minh thông tin đăng nhập của bạn
    <small>
      Vui lòng liên hệ với chúng tôi để được hỗ trợ, hoặc có thể thử lại sau vài
      phút. Trân trọng.
    </small>
  </h1>
  <button
    (click)="navigateToLogin()"
    type="submit"
    class="btn btn-primary btn-lg px-5 waves-effect waves-themed"
  >
    <span> Quay về đăng nhập </span>
  </button>
</app-auth-layout>

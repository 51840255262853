import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppRoutingService } from '@core/routing/app-routing.service';
import { finalize, take } from 'rxjs';
import { InputPasswordComponent } from '@features/auth/shared/components/input-password/input-password.component';
import Swal from 'sweetalert2';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { ChangePasswordErrorCode } from '@features/auth/core/domain/account/change-password-error.model';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputPasswordComponent,
    TranslocoModule,
  ],
  selector: 'form-change-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss'],
})
export class ChangeDefaultPasswordComponent implements OnInit {
  @Output() success = new EventEmitter();

  private readonly appRoute = inject(AppRoutingService);
  private readonly service = inject(VerifyAccountFirstLoginService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toart = inject(ToastrService);

  changePasswordForm!: FormGroup;
  username: string = '';
  showPassword: boolean = false;
  oldPasswordNotMatchError: boolean = false;
  waitingToVerify: boolean = false;

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm() {
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [this.passwordsMatchValidator],
      }
    );
  }

  // Kiểm tra pass và new pass bằng nhau
  private passwordsMatchValidator(
    group: FormGroup | any
  ): { [key: string]: boolean } | null {
    const password = group?.get('newPassword')?.value;
    const confirmPassword = group?.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordsNotMatch: true };
  }

  onChangePassword() {
    if (this.changePasswordForm.invalid) {
      return;
    }

    const { newPassword } = this.changePasswordForm.getRawValue();
    this.waitingToVerify = true;

    this.service
      .changeDefaultPassword(newPassword)
      .pipe(
        take(1),
        finalize(() => {
          this.waitingToVerify = false;
        })
      )
      .subscribe({
        next: (value) => {
          this.success.emit();
        },
        error: (err) => {
          this.handleErrorWhenChangePassword(err);
        },
      });
  }

  handleErrorWhenChangePassword(error: Error) {
    if (error.message == ChangePasswordErrorCode.OLD_PASSWORD_NOT_MATCH) {
      this.oldPasswordNotMatchError = true;
      return;
    }
    this.success.emit();
  }

  navigateToLogin() {
    this.appRoute.navigateToLoginPage();
  }

  showErrorSwal2(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Thông báo lỗi khôi phục mật khẩu',
      confirmButtonText: 'Ok! Tôi hiểu rồi',
      html: message,
      customClass: {
        popup: 'true',
      },
    });
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import {
  Observable,
  OperatorFunction,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  take,
} from 'rxjs';
import {
  NgSelect2Module,
} from '@shared/components/lib-ng/lib-ng-select2/src/public_api';
import { SharedModule } from '@shared/shared.module';
import {
  TranslocoModule,
  TranslocoService,
  provideTranslocoScope,
} from '@ngneat/transloco';
import { DATE } from '@shared/helpers/date-time/date';
import { languagePathBuilder } from '@features/auth/shared/languages';
import { RouterLink } from '@angular/router';
import {
  IRequirementModel
} from "@features/auth/implements/require-update-info/interfaces/requirement-viewmode.interface";
import { ProfileService } from '@features/auth/core/domain/profile/profile.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelect2Module,
    SharedModule,
    TranslocoModule,
    RouterLink,
  ],
  providers: [provideTranslocoScope('auth')],
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {

  public languageService = inject(TranslocoService);
  public service = inject(ProfileService);
  public notify = inject(ToastrService);
  public fb = inject(FormBuilder);

  public TRANSLOCO_PATH = languagePathBuilder(['component', 'profile']);
  public TRANSLOCO_VALIDATE = languagePathBuilder([
    'component',
    'profile',
    'errors',
  ]);
  public currentDate = DATE.formatCustom(
    DATE.now().toISOString(),
    DATE.formatDate,
    'vi',
  );

  public listRequirement: Array<IRequirementModel> = [];

  public changeInfomationForm!: FormGroup;

  public showLoadingWhenSave: boolean = false;
  public showErrorWhenSave: boolean = false;
  public errorWhenGetData: boolean = false;

  ngOnInit() {
    this.changeInfomationForm = this.fb.group({
      ho_ten: ['', Validators.compose([Validators.required])],
      email: [{ value: '', disabled: true }],
      avatar: [{ value: '', disabled: true }],
    });

    this.service.getAccountProfile().subscribe({
      next: (value) => {
          this.changeInfomationForm.patchValue({
            ho_ten: value.ho_ten,
            email: value.email,
            avatar: value.avatar,
          });
      },
      error: (err) => {
        console.log(err);
      },
    })
  }



  onSubmit() {
    if (this.changeInfomationForm.invalid) {
      this.showErrorWhenSave = true;
      return;
    }

    this.showLoadingWhenSave = true;

    this.service.updateAccountProfile(this.changeInfomationForm.getRawValue())
    .pipe(
      finalize(() => {
        this.showLoadingWhenSave = false;
      })
    )
    .subscribe({
      next: (value) => {
          console.log(value);
      },
      error: (err) => {
        console.error(err);
        this.showErrorWhenSave = true;
      },
    })

  }
}

<app-auth-layout *transloco="let translated">
  <h1 class="fw-500 mb-2 mt-5 text-center">
    {{ translated("auth.component.forgotPassword.title") }}
    <small>
      {{ translated("auth.component.forgotPassword.titleDesc") }}
      <!-- Sau khi điền đầy đủ các thông tin bên dưới -->
      <!-- chúng tôi sẽ gửi đến bạn mã OTP gồm 6 chữ số vào địa chỉ email bạn cung cấp. -->
    </small>
  </h1>
  @if (this.displayLogicError) {
  <div class="alert alert-danger" role="alert">
    <strong>
      {{ translated("auth.shared.errors.TechnicalError") }}
    </strong>
  </div>
  } @switch (CURRENT_STEP) { @case (CONTROLLER_STEP.INPUT_USER_NAME) {
  <app-input-username
    (hintEmail)="this.hintEmailEmit($event)"
    (username)="this.formValues.username = $event"
    (goBack)="this.navigateToLogin()"
  >
  </app-input-username>
  } @case (CONTROLLER_STEP.INPUT_EMAIL_ADDRESS) {
  <app-input-email
    [hintEmail]="this.formValues.hintEmail"
    [username]="this.formValues.username"
    (goBack)="this.CURRENT_STEP = 0"
    (emitSuccess)="this.formValues.email = $event; this.CURRENT_STEP = 2"
  >
  </app-input-email>
  } @case (CONTROLLER_STEP.NOTIFY) {
  <app-notify-after-send-email
    (goToLogin)="this.navigateToLogin()"
    (resendEmail)="this.resendEmail()"
  >
  </app-notify-after-send-email>
  } }
</app-auth-layout>

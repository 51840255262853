<app-auth-layout *transloco="let translated">
  <h1 class="fw-500 fs-xxl mb-2 mt-3 text-center">
    <strong>
      {{
        translated(
          "auth.component.accountfirstLogin.verifyDevice.title1"
        )
      }}
      <!-- Chào bạn! <br />
      Tài khoản của bạn đang hoạt động trên thiết bị chưa được xác minh. -->
      <br />
      {{
        translated(
          "auth.component.accountfirstLogin.verifyDevice.title2"
        )
      }}
      <!-- Vui lòng xác minh thiết bị này trước khi tiếp tục sử dụng ứng dụng -->
    </strong>
    <small>
      {{
        translated(
          "auth.component.accountfirstLogin.verifyDevice.titleDesc"
        )
      }}
      <!-- Hệ thống sẽ gửi một mã xác minh đến địa chỉ email đã đăng ký khi bạn nhấn -->
      <!-- "Gửi mã xác minh thiết bị" -->
    </small>
    <img
      class="w-50"
      style="border-radius: 4px"
      [src]="this.iconPath"
      alt="device-icon"
    />
    <br />
  </h1>

  @if(this.errorMessage) {
  <div class="invalid-feedback fs-md d-block mt-1 text-center mb-3">
    {{ translated("auth.shared.errors.TechnicalError") }}
    <!-- Lỗi kỹ thuật: Lỗi khi gửi mã xác minh thiết bị, vui lòng thử lại trong sau -->
    <!-- vài phút hoặc liên hệ với chúng tôi để được hỗ trợ -->
  </div>
  }

  <div class="modal-footer justify-content-center">
    <button
      type="button"
      (click)="this.navigateToLogin()"
      class="mr-3 btn btn-outline-default btn-lg waves-effect waves-themed"
    >
      <span>
        {{
          translated(
            "auth.component.accountfirstLogin.verifyDevice.goBack"
          )
        }}
      </span>
    </button>
    <button
      class="btn btn-primary btn-lg"
      style="cursor: pointer"
      [disabled]="this.isFirstSend"
      (click)="onVerify()"
    >
      @if (this.isFirstSend) {
      <span
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      >
      </span>
      }

      {{
        translated(
          "auth.component.accountfirstLogin.verifyDevice.onSend"
        )
      }}
    </button>
  </div>
</app-auth-layout>

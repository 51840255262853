<!-- STATE 1 -->
<h1 class="fw-500 fs-xxl text-center" *transloco="let translated">
  <strong>
    {{
      translated("auth.component.accountfirstLogin.formChangePassword.title")
    }}
  </strong>
  <small>
    {{
      translated("auth.component.accountfirstLogin.formChangePassword.title")
    }}
  </small>
  <br />
</h1>

<form
  [formGroup]="changePasswordForm"
  (ngSubmit)="onChangePassword()"
  *transloco="let translated"
>
  <!-- #Nhập lại mật khẩu mới -->
  <div class="form-group">
    <!-- Check khoong trung tai khoan -->
    <app-input-password
      [label]="
        translated(
          'auth.component.accountfirstLogin.formChangePassword.newPasswordTitle'
        )
      "
      [class]="'form-control form-control-lg'"
      [disabled]="this.changePasswordForm.get('newPassword')?.disabled ?? false"
      (changePassword)="
        this.changePasswordForm.get('newPassword')?.setValue($event)
      "
    >
    </app-input-password>
  </div>

  <div class="form-group">
    <label class="form-label"
      >{{
        translated(
          "auth.component.accountfirstLogin.formChangePassword.confirmNewPassowrd"
        )
      }}<span class="text-danger"> (*)</span></label
    >
    <!-- INPUT FORM -->
    <div class="input-group">
      <input
        autocomplete="off"
        formControlName="confirmPassword"
        type="{{ this.showPassword ? 'text' : 'password' }}"
        [class]="'form-control form-control-lg'"
        [class.is-invalid]="
          (changePasswordForm.get('confirmPassword')?.hasError('required') ||
            changePasswordForm.hasError('passwordsNotMatch')) &&
          this.changePasswordForm.get('confirmPassword')?.dirty
        "
      />

      <div
        class="input-group-append"
        (click)="this.showPassword = !this.showPassword"
      >
        <span class="input-group-text">
          <i *ngIf="this.showPassword" class="fal fa-eye"></i>
          <i *ngIf="!this.showPassword" class="fal fa-eye-slash"></i>
        </span>
      </div>

      <div
        class="invalid-feedback"
        *ngIf="
          !changePasswordForm.get('confirmPassword')?.hasError('required') &&
          changePasswordForm.hasError('passwordsNotMatch') &&
          this.changePasswordForm.get('confirmPassword')?.dirty
        "
      >
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.confirmNewPasswordNotmatchError"
          )
        }}
      </div>

      <div
        class="invalid-feedback"
        *ngIf="
          changePasswordForm.get('confirmPassword')?.hasError('required') &&
          !changePasswordForm
            .get('confirmPassword')
            ?.hasError('passwordsNotMatch') &&
          this.changePasswordForm.get('confirmPassword')?.dirty
        "
      >
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.confirmPassowrmShouldNotBeEmpty"
          )
        }}
      </div>
    </div>
  </div>

  <!-- #Footer -->
  <div class="modal-footer justify-content-center border-bottom">
    <button
      type="button"
      (click)="this.navigateToLogin()"
      class="mr-3 btn btn-outline-default btn-lg waves-effect waves-themed"
      [disabled]="this.changePasswordForm.disabled"
    >
      <span>
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.goBack"
          )
        }}
      </span>
    </button>

    <button
      type="submit"
      class="btn btn-primary btn-lg px-5 waves-effect waves-themed"
      [disabled]="
        this.changePasswordForm.disabled ||
        !this.changePasswordForm.dirty ||
        this.changePasswordForm.invalid ||
        this.waitingToVerify
      "
    >
      <span
        *ngIf="this.waitingToVerify"
        class="spinner-border spinner-border-sm mr-1"
        role="status"
        aria-hidden="true"
      ></span>
      <span>
        {{
          translated(
            "auth.component.accountfirstLogin.formChangePassword.onChangePassword"
          )
        }}
      </span>
    </button>
  </div>
</form>

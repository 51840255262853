import { Component, OnDestroy, OnInit, inject, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FinishedFormComponent,
  ChangeDefaultPasswordComponent,
} from './components/public-api';
import { VerifyAccountFirstLogin } from '../../core/domain/account-first-login/models/verify-account-first-login.model';
import { Subject, takeUntil } from 'rxjs';
import { VerifyEmailComponent } from '@features/auth/implements/account-first-login/components/verify-email/form-email.component';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageHelper } from '@features/auth/shared/helpers/storage.helper';
import { VerifyAccountFirstLoginService } from '@features/auth/core/domain/account-first-login/verify-account-first-login.service';
import { AuthLayoutComponent } from '@features/auth/shared/components/auth-layout/auth-layout.component';
import { VerifyDeviceComponent } from './components/verify-device/verify-device.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FinishedFormComponent,
    VerifyEmailComponent,
    ChangeDefaultPasswordComponent,
    AuthLayoutComponent,
    VerifyDeviceComponent,
    TranslocoModule,
  ],
  providers: [],
  selector: 'app-form-nhap',
  templateUrl: './require-update-info.component.html',
  styleUrls: ['./require-update-info.component.scss'],
})
export class FormCapNhatThongTin implements OnInit, OnDestroy {
  private readonly service = inject(VerifyAccountFirstLoginService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  destroy$$ = new Subject<void>();

  public readonly CONTROLER_STEP = {
    CHANGE_PASSWORD: 0,
    VERIFY_EMAIL: 1,
    SEND_VERIFY_EMAIL_CODE_SUCCESS: 2,
    VERIFY_PHONE_NUMBER: 3,
    FINISH: 5,
  };
  public currentStep = -1;

  emailFormValue!: string;
  username: string = '';
  redirectFromVerifyOTP = '';

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$$))
      .subscribe((params) => {
        this.redirectFromVerifyOTP = params['fr'] ?? '';
        this.activeFormByKey(this.redirectFromVerifyOTP !== 'otp');
      });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  activeFormByKey(isFirstCall?: boolean) {
    this.service
      .getRequirementOfAccountWhenFirstLogin()
      .pipe()
      .subscribe({
        next: (firstRequire) => {
          if (!firstRequire) {
            // Case first call have no require after login.
            if (isFirstCall) {
              window.location.href = StorageHelper.getCallBackURL();
              return;
            }
            this.currentStep = this.CONTROLER_STEP.FINISH;
            return;
          }

          switch (firstRequire.action_key) {
            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PASSWORD:
              this.currentStep = this.CONTROLER_STEP.CHANGE_PASSWORD;
              isDevMode() &&
                console.log(`Enable form change password first login`);
              break;

            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_EMAIL_KEY:
              this.currentStep = this.CONTROLER_STEP.VERIFY_EMAIL;
              isDevMode() && console.log(`Enable form verify email`);
              break;

            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_PHONE_KEY:
              this.currentStep = this.CONTROLER_STEP.VERIFY_PHONE_NUMBER;
              isDevMode() && console.log(`Enable form verify phone number`);
              break;

            case VerifyAccountFirstLogin.REQUIRE_TO_UPDATE_DEVICE_KEY:
              this.router.navigate(['cap-nhat-thong-tin', 'device']);
              isDevMode() && console.log(`Enable form verify phone number`);
              break;
          }

          isDevMode() && console.groupEnd();
        },
        error: (err) => {
          console.log(err);
          this.router.navigate(['cap-nhat-thong-tin', 'er']);
        },
      });
  }
}
